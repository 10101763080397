@charset "utf-8";
@import "variables";



#intro{
	writing-mode: vertical-rl;
	-ms-writing-mode: tb-rl;
	-webkit-writing-mode:vertical-rl;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	p{
		white-space: nowrap;
		// font-size: 16px;
		font-size: 4.2vw;
		letter-spacing: .4em;
		span{
			opacity: 0;
			filter: blur(4px);
			transition: all 1s;
		}
		span.on{
			opacity: 1;
			filter: blur(0px);
		}
	}
	p:nth-child(2){
		margin-top: 4em; 
	}
}

#main-view{
	overflow: hidden;
	position: relative;
	margin: 0 10px;
	#catch{
		line-height: $lineHeight;
		writing-mode: vertical-rl;
		-ms-writing-mode: tb-rl;
		-webkit-writing-mode:vertical-rl;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		transform: translate(-50%, -50%);
		p{
			white-space: nowrap;
			color: #fff;
			// font-size: 16px;
			font-size: 4.2vw;
			letter-spacing: .4em;
		}
		p:nth-child(2){
			margin-top: 4em; 
		}
	}
}

.slides {
	position: relative;
	span{
		overflow: hidden;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		img{
			transition: all 8s linear;
		}
	}
	span:nth-child(odd) {
		img {
			transform: scale(1.2);
		}
	}
	span:nth-child(odd).show {
		z-index: 1;
		position: relative;
		img {
			transform: scale(1);
		}
	}
	span:nth-child(even) {
		img {
			transform: scale(1);
		}
	}
	span:nth-child(even).show {
		z-index: 1;
		position: relative;
		img {
			transform: scale(1.2);
		}
	}
}


.sec01{
	
}

.sec02{
	padding: 5vw 0 8vw;
	.sec-body{
		.sub02{
			.key-wrap{
				position:relative;
				text-align: right;
				.key-svg{
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: 1;
					width: 50%;
					transform: translateY(9.5%) scale(1.5);
					transition: all 3s;
				}
				.key-svg.on{
					transform: translateY(9.5%) scale(1);
				}
				.key-img{
					display: inline-block;
					width: 45%;
					max-width: 310px;
				}
			}
		}
	}
}

.sec03{
	.inner{
		max-width: 800px;
	}
	.sec-body{
		.sec-left{
			.key-img{
				width: 50%;
				max-width: 390px;
				margin: 10px auto;
			}
		}

		.sec-right{
			h3{
				position: relative;
				padding: 5px 0;
				margin: 8% 0 25px;
				small{
					font-size: .67em;
					margin-right: 1em;
					position: relative;
					top: -3px;
					letter-spacing: 2px;
				}
				strong{
					font-size: 1.33em;
				}
			}
			h3:after{
				content: '';
				display: block;
				position: absolute;
				bottom: 0;
				left: 50%;
				width: 0;
				height: 1px;
				background-color: #000;
				transform: translateX(-50%);
				transition: all 2s;
			}
			h3.on:after{
				width: 100%;
			}
			.key-sent{
				line-height: 2.4;
				margin-bottom: 10px; 
			}
		}
	}
}


.sec05{
	margin-top: -3.5%;
	.sec-body{
		.sub-sec{
			.sub-sec-lead{
					margin: 0;
			}
		}
	}
}

.sec06{
	padding: 5vw 0 10vw;
	.inner{
		max-width: 860px;
	}
	.sec-head{
		padding: 0 0 8vw;
		.icon{
			line-height: 0;
			width: 25%;
			max-width: 110px;
			margin: auto;
			position: relative;
			top: 100px;
			opacity: 0;
			transform: rotateY(720deg);
			transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1);
			transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
		}
		.icon.on{
			top: 0;
			opacity: 1;
			transform: rotateY(0deg);
		}
	}
	.sec-body{

	}
}
#flow{
		
}
#flow-trig{
	cursor: pointer;
	position: relative;
	z-index: 1;
	text-align: center;
	color: #fff;
	font-size: 16px;
	line-height: 1.8;
	font-family: $fontJa;
	font-weight: 600;
	letter-spacing: .2em;
	background-color: #000;
	width: 80%;
	max-width: 580px;
	margin: auto;
	padding: 10px 15px;
	// transform: translateY(50%);
	box-sizing: border-box;
	span{
		display: inline-block;
	}
}
#flow-dtl{
	display: none;
	position: relative;
	border: 1px solid #000;
	padding: 15% 6% 3%;
	ol{
		position: relative;
		z-index: 2;
		li{
			position: relative;
			.li-in{
				overflow: hidden;
				padding: 8% 0;
				.key-img{
					float: left;
					width: 46%;
					margin: 0 7% 10px 0;
				}
				.li-dtl{
					.li-head{
						letter-spacing: .2em;
						// margin: 0 0 2%;
						span{
							position: relative;
							top: -.2em;
						}
						i{
							font-size: 2em;
							font-style: italic;
							margin-left: 5px;
						}
					}
				}
			}
		}
		li:after{
			content: '';
			display: block;
			width: 15px;
			height: 15px;
			position: absolute;
			bottom: 0;
			left: 50%;
			border-top: 1px solid #9d9d9d;
			border-right: 1px solid #9d9d9d;
			transform: translate(-50%, 0) rotate(135deg);
		}
		li:last-of-type:after{
			display: none;
		}
	}
}
#flow-dtl:before{
	content: '';
	display: block;
	position: absolute;
	top: 5px;
	left: 5px;
	width: calc(100% - 10px);
	height: calc(100% - 10px);
	border: 1px solid #000;
	box-sizing: border-box;
}

.sec07{
	.inner{
		max-width: 1200px;
	}
	.sec-head{
		.key-lead{
			margin-top: -10px;
			span{
				font-size: 1.33em;
			}
		}
	}
	.sec-body{
		position: relative;
		// perspective: 1000px;
		.key-svg{
			position: absolute;
			top: 10px;
			left: 50%;
			width: 100%;
			// transform: translateX(-50%) scale(1.5) rotateX(45deg);
			transform: translateX(-50%) scale(1.5);
			transition: all 3s;
		}
		.key-svg.on{
			// transform: translateX(-50%) scale(1) rotateX(0deg);
			transform: translateX(-50%) scale(1);
		}
		.key-img{
			width: 50%;
			max-width: 430px;
			margin: auto;
		}
		.key-lead{
			text-align: center;
			margin: 0;
			padding: 4% 0;
			strong,
			small{
				display: inline-block;
				vertical-align: middle;
			}
			strong{
				font-size: 1.66em;
			}
			small{
				font-size: .9em;
				margin-left: 1em;
			}
		}
		.key-sent{
			max-width: 590px;
			margin: auto;
			line-height: 2.4;
		}
	}
}

.sec08{
	.inner{
		max-width: 780px;
	}
	.sec-body{
		.sub-sec{
			margin: 0;
			border-top: 1px solid #000;
			padding: 10% 0;
			ul{
				li{
					.li-in{
						letter-spacing: -.4em;
						.key-img,
						.product-dtl{
							letter-spacing: normal;
						}
						.product-dtl{
							h4{
								font-size: 16px;
								line-height: 1.8;
								letter-spacing: .2em;
								small{
									display: block;
									font-size: 14px;
								}
							}
						}
					}
				}
			}
		}
		.sub01{
			ul.slick{
				li{
					.li-in{
						padding: 0 10px;
						.product-dtl{
							padding: 0 0 10px;
							h4{
								padding: 6% 0;
							}
						}
					}
				}
			}
		}
		.sub02{
			ul{
				li{
					margin-top: 12%;
					.li-in{
						padding: 0 10px;
						.product-dtl{
							h4{
								padding: 6% 0;
							}
						}
					}
				}
				li:first-of-type{
					margin-top: 0;
				}
			}
		}
	}
}

.slick-dots{
	position: relative;
	bottom: 0;
	margin: 10px 0 -10px;
}
.slick-dots li button:before{
	-webkit-text-stroke: 0;
}
.slick-dots li.slick-active button:before{
	color: #000;
}



@media screen and (min-width: 600px) {
	#main-view{
		#catch{
			p{
				// font-size: 18px;
			}
		}
	}

	.sec06{

	}
	#flow-trig{
		font-size: 18px;
		padding: 15px;
	}
	#flow-dtl{
		padding: 9% 8% 3%;
		ol{
			li{
				.li-in{
					.li-dtl{
						.li-head{
							font-size: 16px;
						}
					}
				}
			}
			li:after{
				width: 20px;
				height: 20px;
			}
		}
	}

	.sec08{
		.sec-body{
			.sub-sec{
				ul{
					li{
						.li-in{
							.product-dtl{
								h4{
									font-size: 18px;
								}
							}
						}
					}
				}
			}
			.sub01{
				padding: 10% 3.5%;
				ul.slick{
					li{
						.li-in{
							padding: 0 6%;
						}
					}
				}
			}
			.sub02{
				ul{
					li{
						margin-top: 8%;
						.li-in{
							padding: 0;
							border: 1px solid #000;
							.key-img,
							.product-dtl{
								display: inline-block;
								vertical-align: middle;
								width: 50%;
							}
							.product-dtl{
								padding: 0 20px;
								box-sizing: border-box;
								h4{
									padding: 0;
									margin: -10px 0 20px;
								}
							}
						}
					}
				}
			}
		}
	}

}


@media screen and (min-width: 800px) {

	#intro{
		p{
			font-size: 2vw;
		}
	}

	#main-view{
		margin: 0;
		#catch{
			p{
				font-size: 2vw;
			}
		}
	}

	.sec02{
		.sec-body{
			.sub-sec{
				position: relative;
			}
			.sub01{
				.key-img{
					width: 80%;
					max-width: 630px;
				}
				.sub-sec-lead{
					position: absolute;
					top: 48%;
					right: 0;
					z-index: 2;
					transform: translateY(-50%);
				}
			}
			.sub02{
				.sub-sec-lead{
					position: absolute;
					top: 0;
					left: -1em;
					z-index: 1;
				}
			}
		}
	}

	.sec03{
		.sec-body{
			overflow: hidden;
			.sec-left{
				float: left;
				width: 50%;
				max-width: 390px;
				.key-img{
					width: 100%;
					margin: 0;
				}
			}
			.sec-right{
				float: right;
				width: 45%;
				max-width: 360px;
				margin-left: 5%;
				h3{
					margin: 0 0 8%;
				}
			}
		}
	}

	.sec06{

	}
	#flow-trig{
		font-size: 20px;
		padding: 20px;
	}
	#flow-dtl{
		padding: 9% 10% 3.5%;
		ol{
			li{
				.li-in{
					.li-dtl{
						.li-head{
							font-size: 20px;
						}
						.li-body{
							// font-size: 15px;
						}
					}
				}
			}
			li:after{
				width: 25px;
				height: 25px;
			}
		}
	}

	.sec08{
		.sec-body{
			.sub-sec{
				ul{
					li{
						.li-in{
							.product-dtl{
								h4{
									font-size: 20px;
								}
							}
						}
					}
				}
			}
		}
	}

}


@media screen and (min-width: 1000px) {
	#intro{
		p{
			font-size: 1.7vw;
		}
	}

	#main-view{
		#catch{
			p{
				font-size: 1.7vw;
			}
		}
	}

	.sec06{

	}
	#flow-trig{
		font-size: 21px;
		padding: 25px;
	}
	#flow-dtl{
		padding: 9% 11.5% 3.5%;
	}
	

}


@media screen and (min-width: 1280px) {
	
	.sec02{
		padding: 65px 0 110px;
	}
	.sec04{
		padding-top: 90px;
	}
	.sec06{
		padding: 65px 0 110px;
		.sec-head{
			padding: 0 0 70px;
		}
	}

	.sec08{
		padding: 100px 0 0;
		
	}

}